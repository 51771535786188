<template>
    <div class="r_content">
        <div id="demo" class="block">
            <vue-seamless-scroll :data="rbookInfos" :class-option="classOption" @click="handleClick($event)">
                <div :style="{ width: '100%', height: '360px' }">
                    <div class="box" v-for="(m, idx) in rbookInfos" :key="idx"
                        :style="{ width: boxWidth + 'px', height: boxWidth + (boxWidth / 3) + 'px' }">
                        <img :src="m.pic" alt="" style="width:100%" :data-id="m.id">
                    </div>
                </div>
            </vue-seamless-scroll>

        </div>
        <div class="top"></div>
        <div class="bottom"></div>
        <div class="layer" style="display: none;">
            <a href="javascript:void(0)"><img src="@/assets/images/icon_close.svg" /></a>
        </div>
        <van-popup v-model:show="showDetail" :round="true" @closed="handleClose"
            :style="{ width: '720px', height: modalHeight, top: popupTop }">
            <div ref="bookpopup" style="width:100%;height:100%;background:white">
                <van-loading v-if="bookUrlLoading" text-color="#0094ff" color="#0094ff" size="120" type="spinner" vertical
                    style="top:40%;left:0;right:0;bottom:0;margin:auto;z-index:999;position:absolute">加载中</van-loading>
                <iframe id="bookIframe" :style="{ width: '100%', height: '100%' }" frameborder="0">
                </iframe>
                <div
                    style="width: 200x;height: 200px;position: fixed;margin: auto;top: 5px;right: 5px;background-color: #fff;padding: 5px;border-radius: 8px;opacity: 0.9;">
                    <div id="qrcode" style=""></div>
                    <div
                        style="background: rgba(255,255,255,0.5);margin: 20px 0 0;color: #000;border-radius: 10px;font-size: 14px;padding: 5px 0;">
                        扫码阅读</div>
                </div>
                <van-button color="gray" style="position: fixed;margin: auto;top: 5px;left: 5px;width:50px;height:50px;"
                    type="default" icon="close" size="large" round @click="() => showDetail = false"></van-button>
            </div>
        </van-popup>

        <van-popup v-model:show="showLoading" :round="true" style="height:300px;width:300px;">
            <van-loading text-color="#0094ff" color="#0094ff" size="120" type="spinner" vertical
                style="margin-top:30%">加载中</van-loading>
        </van-popup>
        <div style="position: fixed; bottom: 30%; right: 0;width: 100px;height: 100px;z-index: 999;">
            <van-row>
                <van-col span="24">
                    <!-- <van-button color="#33da63" style="background:rgba(51,218,99,0.85);" type="success"
                        icon="live" size="large" @click="getBookInfos(1)">读书</van-button> -->
                        <van-floating-bubble icon="search" @click="getBookInfos(1)" axis="xy" @offset-change="handleChange(1, $event)"
                        style="width: 100px;height: 50px;font-size: 24px;background-color: rgba(51, 218, 99, 0.85);border-radius: 0px;"
                        v-model:offset="offset1"><van-icon name="live" size="25" />读书</van-floating-bubble>
                    </van-col>
            </van-row>
            <van-row style="margin-top: 20px;">
                <van-col span="24">
                    <!-- <van-button type="success" icon="audio" size="large"
                        style="background:rgba(51,218,99,0.85);" @click="getBookInfos(2)">听书</van-button> -->
                        <van-floating-bubble icon="search" @click="getBookInfos(2)" axis="xy" @offset-change="handleChange(2, $event)"
                        style="width: 100px;height: 50px;font-size: 24px;background-color: rgba(51, 218, 99, 0.85);border-radius: 0px;"
                        v-model:offset="offset2"><van-icon name="audio" size="25" />听书</van-floating-bubble>
                    </van-col>
            </van-row>
            <van-row style="margin-top: 20px;">
                <van-col span="24">
                    <!-- <van-button type="success" icon="search" size="large" style="background:rgba(51,218,99,0.85);"
                        @click="showSearch">搜索</van-button> -->
                    <van-floating-bubble icon="search" @click="showSearch" axis="xy"  @offset-change="handleChange(3, $event)"
                        style="width: 100px;height: 50px;font-size: 24px;background-color: rgba(51, 218, 99, 0.85);border-radius: 0px;"
                        v-model:offset="offset3"><van-icon name="search" size="25"/>搜索</van-floating-bubble>
                </van-col>
            </van-row>
        </div>
        <van-popup v-model:show="searchVisiable" :round="true" @closed="() => searchVisiable = false"
            :style="modalStyle">
            <van-search ref="inputFile" v-model="bookName" @focus="onFocus" placeholder="关键字"></van-search>
            <SimpleKeyboard ref="SimpleKeyboard" @onChange="onChangeKeyboard" @search="handelSearch"
                v-if="!searchBookVisiable" @clear="handleClear" @bksp="handleBksp" />
            <div :style="{ width: '100%', height: '360px' }" v-else-if="bookList.length > 0">
                <div class="box" v-for="(m, idx) in bookList" :key="idx"
                    :style="{ width: boxWidth + 'px', height: boxWidth + (boxWidth / 3) + 'px' }">
                    <img :src="m.picUrl" alt="" style="width:100%" :data-id="m.id" @click="handleShowBook(m)">
                </div>
            </div>
            <van-empty description="暂无数据" v-else />
        </van-popup>
    </div>
</template>
  
<script>
import '@/assets/style.css'
import jsrsasign from 'jsrsasign'
import QRCode from 'qrcodejs2'
import axios from 'axios'
import vueSeamlessScroll from 'vue-seamless-scroll/src'
import { sign, ksort } from '@/utils/wsign'
import { showFailToast, showToast } from 'vant'
import SimpleKeyboard from './SimpleKeyboard.vue'
const appId = process.env.VUE_APP_APPID
const rentId = process.env.VUE_APP_RENTID
const userId = process.env.VUE_APP_USERID
const publicKeyStr = process.env.VUE_APP_PUBLIC_KEY
const companyId = process.env.VUE_APP_COMPANYID
export default {
    name: 'WaterFall',
    components: {
        vueSeamlessScroll,
        SimpleKeyboard
    },
    data() {
        return {
            modalStyle: { width: '720px', height: '900px', top: '70%', background: 'white' },
            bookName: '',
            searchVisiable: false,
            searchBookVisiable: false,
            host: 'https://s.zhangyue.com',
            bookType: 1,//1-电子书，2-音频书
            showDetail: false,
            boxWidth: 200,
            boxHeight: 350,
            classOption: {
                step: 1, // 数值越大速度滚动越快
                limitMoveNum: 40, // 开始无缝滚动的数据量 this.cityData.length
                hoverStop: false, // 是否开启鼠标悬停stop
                direction: 0, // 0向下 1向上 2向左 3向右
                openWatch: true // 开启数据实时监控刷新dom
                // singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                // waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
            },
            // companyId: '105178',
            bookFileurl: '',
            rbookIds: [],
            lbookIds: [],
            rbookInfos: [],
            lbookInfos: [],
            popupTop: 0,
            modalHeight: '1140px',
            showLoading: false,
            bookUrlLoading: false,
            page: 0,
            lbpage: 0,//听书页数
            searchPage: 1,
            searchPageSize: 10,
            bookList: [],
            offset1: { x: 980, y: 1600 },
            offset2: { x: 980, y: 1700 },
            offset3: { x: 980, y: 1800 }
        }
    },
    created() {
        var h1 = window.localStorage.getItem('btn1')
        var h2 = window.localStorage.getItem('btn2')
        var h3 = window.localStorage.getItem('btn3')
        var newH1, newH2,newH3
        var windowHeight = window.innerHeight
        if (windowHeight <= 1920) {
            newH1 = 1920 / 2
            newH2 = 1920 / 2 + 100
            newH3 = 1920 / 2 + 200
        } else if (windowHeight > 1920 && windowHeight < 3840) {
            newH1 = windowHeight / 2 
            newH2 = windowHeight / 2 + 100
            newH3 = windowHeight / 2 + 200
        } else {
            newH1 = windowHeight / 2
            newH2 = windowHeight / 2 + 100
            newH3 = windowHeight / 2 + 200
        }
        if (h1) {
            this.offset1 = JSON.parse(h1)
        } else {
            this.offset1.y = newH1
        }
        if (h2) {
            this.offset2 = JSON.parse(h2)
        } else {
            this.offset2.y = newH2
        }
        if (h3) {
            this.offset3 = JSON.parse(h3)
        } else {
            this.offset3.y = newH3
        }
        this.init()
    },
    mounted() {
        const clientHeight = document.documentElement.clientHeight
        if (clientHeight <= 1920) {
            this.modalHeight = document.documentElement.clientHeight / 2 + 'px'

        }
        // else if (clientHeight > 1920) {
        //     this.modalHeight = document.documentElement.clientHeight / 3 + 'px'
        // }
    },
    methods: {
        handleChange(type,e) {
            console.log(e)
            window.localStorage.setItem("btn"+type,JSON.stringify(e))
        },
        showSearch() {
            this.modalStyle.top = this.offset3.y + 'px'
            this.searchVisiable = true
        },
        onFocus() {
            this.searchBookVisiable = false
            document.activeElement.blur()
        },
        onChangeKeyboard(v) {
            this.bookName = v

        },
        handelSearch() {
            const that = this
            if (that.bookName === '') {
                showFailToast('请输入书名')
                return
            }
            var url = '/zysearch/search/index'
            var ts = Math.round(new Date().getTime() / 1000).toString()
            const obj = {
                keyword: that.bookName,
                page: that.searchPage,
                pageSize: that.searchPageSize,
                type: that.bookType,
                rentId: rentId,
                appId: appId,
                timestamp: ts,
            }
            const signstr = sign(obj)
            const parmas = ksort(obj)
            url += '?' + parmas + '&sign=' + signstr
            axios.get(url).then(res => {
                that.bookList = []
                if (res.status === 200) {
                    if (res.data.code === 0) {
                        if (res.data.body.bookList) {
                            that.bookList = res.data.body.bookList
                        }
                    }
                } else {
                    showToast('查询失败')
                }
                that.searchBookVisiable = true
            })
        },
        init() {
            this.getComBookFileUrl()
        },
        handleClear() {
            this.bookName = ''
        },
        handleBksp() {
            if (this.bookName.length > 0) {
                if (this.bookName.length === 1) {
                    this.$refs.SimpleKeyboard.backClear()
                    this.bookName = ''
                } else {
                    this.bookName = this.bookName.substring(0, this.bookName.length - 1)
                }
            }
        },
        handleShowBook(bookObj) {
            const that = this
            that.searchVisiable = false
            that.bookUrlLoading = true
            that.popupTop = '60%'
            if (bookObj.bookId) {
                var ts = Math.round(new Date().getTime() / 1000).toString()
                const cid = '1'
                const obj = {
                    appId: appId,
                    rentId: rentId,
                    timestamp: ts,
                    userId: userId
                }
                const signstr = sign(obj)
                var bookDetailUrl = ''
                if (bookObj.bookType === 1) {
                    bookDetailUrl = that.host + '/read' + "?" + 'bid=' + bookObj.bookId + '&cid=' + cid + '&' + ksort(obj) + "&sign=" + signstr
                } else if (bookObj.bookType === 2) {
                    bookDetailUrl = that.host + '/audio/ting' + "?" + 'bookId=' + obj.bookId + '&chapterId=' + cid + '&' + ksort(obj) + "&sign=" + signstr
                } else {
                    showFailToast('数据异常')
                    return
                }
                that.showDetail = true
                that.$nextTick(() => {
                    let iframe = document.getElementById('bookIframe')
                    iframe.src = bookDetailUrl
                    if (iframe.attachEvent) {// IE下
                        iframe.attachEvent("onload", function () {
                            //    后续操作
                            that.bookUrlLoading = false
                        });
                    } else {
                        iframe.onload = function () {
                            //    后续操作
                            that.bookUrlLoading = false
                            that.qrcodeScan(bookDetailUrl)

                        };
                    }
                })
            }
        },
        handleClick(e) {
            const that = this
            that.bookUrlLoading = true
            const bid = e.target.dataset.id
            if (e.y > 5000) {
                that.popupTop = (e.y - 500) + 'px'
            } else if (e.y < 1000) {
                that.popupTop = (e.y + 500) + 'px'
            } else {
                that.popupTop = e.y + 'px'
            }
            if (bid) {
                var ts = Math.round(new Date().getTime() / 1000).toString()
                const cid = '1'
                const obj = {
                    appId: appId,
                    rentId: rentId,
                    timestamp: ts,
                    userId: userId
                }
                const signstr = sign(obj)
                var bookDetailUrl = ''
                if (that.bookType === 1) {
                    bookDetailUrl = that.host + '/read' + "?" + 'bid=' + bid + '&cid=' + cid + '&' + ksort(obj) + "&sign=" + signstr
                } else if (that.bookType === 2) {
                    bookDetailUrl = that.host + '/audio/ting' + "?" + 'bookId=' + bid + '&chapterId=' + cid + '&' + ksort(obj) + "&sign=" + signstr
                } else {
                    showFailToast('数据异常')
                    return
                }
                that.showDetail = true
                that.$nextTick(() => {
                    let iframe = document.getElementById('bookIframe')
                    iframe.src = bookDetailUrl
                    if (iframe.attachEvent) {// IE下
                        iframe.attachEvent("onload", function () {
                            //    后续操作
                            that.bookUrlLoading = false
                        });
                    } else {
                        iframe.onload = function () {
                            //    后续操作
                            that.bookUrlLoading = false
                            that.qrcodeScan(bookDetailUrl)

                        };
                    }
                })
            }
        },
        singer(str) {
            let signPrivateKey = '-----BEGIN PRIVATE KEY-----' + publicKeyStr + '-----END PRIVATE KEY-----';
            let sig = new jsrsasign.crypto.Signature({ "alg": "SHA1withRSA", "prov": "cryptojs/jsrsa", "prvkeypem": signPrivateKey });
            var hashAlg = 'sha1'; // 设置sha1
            var signStr = sig.signString(str, hashAlg); // 加签
            return jsrsasign.hex2b64(signStr)
        },
        getComBookFileUrl() {
            const sign = this.singer('companyId=' + companyId)
            const obj = {
                companyId: companyId,
                sign: sign
            }
            axios({
                url: '/api1/nd_activity/out/company/v3/bookIds',
                method: 'post',
                data: obj,
                timeout: 60000,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 0) {
                        this.bookFileurl = res.data.body.bookFileurl
                        this.getComBookIds()
                    }
                } else {
                    showFailToast('请求失败')
                }
            })
        },
        getComBookIds() {
            if (this.bookFileurl !== '') {
                const url = this.bookFileurl.replaceAll('http://cloud.d.ireader.com', '/api')
                axios.get(url).then(res => {
                    if (res.status === 200) {
                        const data = res.data
                        const rBooks = data.find(item => item.type === 1)
                        const lBooks = data.find(item => item.type === 2)
                        if (rBooks) {
                            this.rbookIds = this.tranBookIds(rBooks.bookIds)
                            this.getBookInfos(this.bookType)
                            setInterval(() => {
                                if (this.bookType === 1) {
                                    this.page = this.page + 1
                                } else {
                                    this.lbpage = this.lbpage + 1
                                }
                                this.getBookInfos(this.bookType, 1)
                            }, 5 * 60 * 1000)
                        } else {
                            showFailToast('图书数据异常')
                        }
                        if (lBooks) {
                            this.lbookIds = this.tranBookIds(lBooks.bookIds)
                        } else {
                            showFailToast('听书数据异常')
                        }
                    }
                }).catch(err => {
                    console.log(err)
                })
            } else {
                showFailToast('图书数据请求失败')
            }
        },
        getBookInfos(type, stateless) {
            if (stateless !== 1) {
                this.showLoading = true
            }
            this.bookType = type
            const sign = this.singer('companyId=' + companyId)
            var showBooks = []
            if (type === 1) {
                var start = this.page * 120
                var end = start + 120
                if (end > this.rbookIds.length) {
                    this.page = 0
                    start = 0
                    end = 120
                }
                if (this.rbookIds.length > 120) {
                    showBooks = this.rbookIds.slice(start, end)
                } else {
                    showBooks = this.rbookIds
                }
            }
            if (type === 2) {
                var lstart = this.lbpage * 120
                var lend = lstart + 120
                if (lend > this.lbookIds.length) {
                    this.lbpage = 0
                    lstart = 0
                    lend = 120
                }
                if (this.lbookIds.length > 120) {
                    showBooks = this.lbookIds.slice(lstart, lend)
                } else {
                    showBooks = this.lbookIds
                }
            }

            const obj = {
                companyId: companyId,
                bookIds: showBooks,
                type: this.bookType,
                sign: sign
            }
            axios({
                url: '/api1/nd_activity/out/company/bookInfos',
                method: 'post',
                data: obj,
                timeout: 60000
            }).then(res => {
                if (res.status === 200) {
                    const array = []
                    for (let key in res.data.body) {
                        array.push({
                            id: key,
                            pic: res.data.body[key].pic
                        })
                    }
                    this.rbookInfos = array

                    this.showLoading = false
                } else {
                    showFailToast('请求失败')
                    this.showLoading = false
                }
            })
        },
        tranBookIds(obj) {
            let arry = []
            for (let key in obj) {
                arry.push(obj[key])
            }
            return this.shuffle(arry)
        },
        shuffle(array) {//对掌阅数据重新洗牌，防止同一类封面的书籍出现在同一个界面
            let res = [], random
            while (array.length > 0) {
                random = Math.floor(Math.random() * array.length)
                res.push(array[random])
                array.splice(random, 1)
            }
            return res
        },
        qrcodeScan(url) {
            const qrcodeDiv = document.getElementById("qrcode")
            qrcodeDiv.innerHTML = ''
            // eslint-disable-next-line no-unused-vars
            const qrcode = new QRCode(qrcodeDiv, {
                width: 200, // 二维码宽度 
                height: 200, // 二维码高度
                text: url, // 浏览器地址url
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.H,
            })
        },
        handleClose() {
            const iframe = document.getElementById('bookIframe')
            iframe.src = ''
        }
    }
}
</script>
<style lang="less">

</style>